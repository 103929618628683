/* eslint-disable camelcase */
import React from 'react';
import { CaretRightIcon } from '@buzzfeed/react-components';
import { useMediaQuery, useTrackingContext } from '../../hooks';
import BrandedBox from '../BrandedBox';
import { Feed } from '../Feed';
import styles from './sponsoredPackage.module.scss';

export const SponsoredPackage = ({ name, data, sponsor, id, cta }) => {
  const screenSize = useMediaQuery();
  const { trackExternalLink, trackInternalLink } = useTrackingContext();

  const positionInUnit = 1;

  const trackSponsorLinkClick = () => {
    const sponsorUrl = sponsor.logo_link_url;
    trackExternalLink({
      subunit_type: 'package',
      subunit_name: id,
      position_in_unit: positionInUnit,
      target_content_type: 'url',
      target_content_id: sponsorUrl,
      target_content_url: sponsorUrl,
      item_type: 'button',
      item_name: 'sponsor',
    });
  };

  const handleSeeMoreTracking = () => {
    if (cta.url.includes('buzzfeed.com')) {
      trackInternalLink({
        subunit_type: 'package',
        subunit_name: id,
        item_type: 'text',
        item_name: 'see_more',
        position_in_unit: positionInUnit,
        position_in_subunit: data.items.length + 1,
        target_content_type: 'feed',
        target_content_id: id,
      });
    } else {
      trackExternalLink({
        subunit_type: 'package',
        subunit_name: id,
        item_type: 'text',
        item_name: 'see_more',
        position_in_unit: positionInUnit,
        target_content_url: cta.url,
      });
    }
  };

  const Heading = <h2 className={styles.heading}> {name} </h2>;

  return (
    <BrandedBox
      heading={Heading}
      color="yellow"
      className="xs-pt4 xs-mb6 md-mb0"
    >
      <div className="xs-flex xs-flex-column md-flex-row md-flex-wrap md-flex-align-center">
        <div className="xs-mb2">
          {sponsor && sponsor.logo_image_url && (
            <div className="xs-flex xs-flex-align-center xs-mb1 md-mb0">
              <a
                href={sponsor.logo_link_url}
                className={styles.sponsorImage}
                onClick={trackSponsorLinkClick}
              >
                <img src={sponsor.logo_image_url} alt={sponsor.logo_alt} />
              </a>
              <h3
                className="xs-text-5"
                dangerouslySetInnerHTML={{ __html: sponsor.logo_tagline }}
              />
            </div>
          )}
        </div>
      </div>
      <Feed
        items={data.items.slice(0, 3)}
        columns={screenSize.isMobile ? 1 : 3}
        typeInfo={data.typeInfo}
        hasRightText={screenSize.isMobile}
        positionInUnit={positionInUnit}
        subunitName={id}
        subunitType='package'
      />
      {cta.url && cta.text && (
        <div className="xs-flex xs-text-4 md-text-2 bold xs-flex-align-center link-blue xs-mt3 xs-mb1">
          <a href={cta.url} onClick={handleSeeMoreTracking}>
            <span>{cta.text}</span>
            <CaretRightIcon
              aria-hidden="true"
              className={`
              ${screenSize.isMobile ? 'svg-6' : 'svg-3'} svg-blue xs-ml1`}
            />
          </a>
        </div>
      )}
    </BrandedBox>
  );
};

export default SponsoredPackage;
