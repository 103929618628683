/* eslint-disable camelcase */
import { SubunitTrackingProvider } from '../../contexts';
import BrandedBox from '../BrandedBox';
import Heading from '../Heading';
import Carousel from '../Carousel';
import { Feed } from '../Feed';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { TastyLogo } from './TastyLogo';

export const TastyTrending = ({ data }) => {
  const screenSize = useMediaQuery();

  const trackingCommon = {
    subunit_name: 'tasty',
    subunit_type: 'package',
    position_in_unit: 10,
  };

  // mobile becomes a carousel
  if (screenSize.isMobile) {
    return (
      <SubunitTrackingProvider value={trackingCommon}>
        <Heading>
          <TastyLogo />
          <h2 className="screen-reader-only"> Tasty </h2>
        </Heading>
        <Carousel
          items={data.items.slice(0, 15)}
          color="blue"
          seeMoreLink={data.typeInfo}
        />
      </SubunitTrackingProvider>
    );
  } else {
    return (
      <SubunitTrackingProvider value={trackingCommon}>
        <BrandedBox
          heading={<TastyLogo />}
          color="blue"
          className="xs-pt6 xs-mt6 xs-mb6 md-mb0"
        >
          <h2 className="screen-reader-only"> Tasty </h2>
          <Feed
            items={data.items.slice(0, 3)}
            columns={3}
            hasSeeMoreLink
            typeInfo={data.typeInfo}
            lazyLoadThumbnails={true}
          />
        </BrandedBox>
      </SubunitTrackingProvider>
    );
  }
};

export default TastyTrending;
