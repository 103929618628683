/* eslint-disable camelcase */
import { Feed } from '../Feed';
import { SubunitTrackingProvider } from '../../contexts';

export const MoreStories = ({
  data,
  positionInUnit,
  subunitName,
  heading,
  className,
}) => {
  const tracking = {
    subunit_name: subunitName,
    subunit_type: 'package',
    position_in_unit: positionInUnit,
  };

  return (
    <SubunitTrackingProvider value={tracking}>
      <div className={`${heading ? 'xs-mt5' : 'xs-mt6'} ${className}`}>
        {!heading && <h2 className="screen-reader-only"> More Stories </h2>}
        <Feed
          items={data.items}
          heading={heading}
          columns={1}
          typeInfo={data.typeInfo}
          hasRightText
          pageSize={data.items.length}
          hasArrows={!!heading}
          lazyLoadThumbnails={true}
        />
      </div>
    </SubunitTrackingProvider>
  );
};

export default MoreStories;
