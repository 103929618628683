import React from 'react';
import styles from './tastyTrending.module.scss';

export const TastyLogo = () => (
  <svg 
    xmlns="http://www.w3.org/2000/svg"
    x="0px" y="0px"
    viewBox="0 0 1174.4 844.4"
    style={{ enableBackground: 'new 0 0 1174.4 844.4' }}
    role="img"
    aria-label="Tasty logo"
    className={styles.tastyLogo}
  >
    <path style={{fill: '#79DCF1'}} d="M765.2,64.9c80.8,42.5,126.9,106.5,154.5,169.5c22.8,52,87.1,253.6,164.5,323c131,117.3,123.4,243.9-50.4,274.7
      C849,864.8,315.2,845.8,94.4,606.3C-51.1,448.5-0.4,294.1,61.3,200.5c31.9-48.4,117-120,175.4-144C353.8,5.2,557.6-43.3,765.2,64.9z
      "/>
    <g style={{ enableBackground: 'new' }}>
      <g>
        <path style={{fill: '#fff'}} d="M1125,404.7l-3-17c-3.4-9.1-7.8-16.4-13.5-22.6c-8.3-8.9-18.3-13.4-29.6-13.4c-1.9,0-3.8,0.1-5.8,0.4
          c-10.4,1.3-18.9,6-25.2,9.9c-1.1,0.7-2.3,1.4-3.5,2.1c-3.8,2.2-8.6,5-13.3,8.9l-2.9,2.3c-2.3,1.9-5.7,5.3-8,7.3
          c0.3-1.3,1.5-4,1.8-5.4c0.9-4.6,1.9-9.2,2.9-13.6l1.1-5.2c1-4.5,1.7-8.6,2.2-11.8c0.4-2.5,0.8-4.6,1.2-6.1
          c0.4-1.7,0.8-3.6,1.2-5.7c0.6-3.2,1.4-6.9,2.3-10.6c0.9-3.4,1.6-6.9,2.2-10.3c0.5-2.4,1-4.8,1.5-7.2c1.3-5.5,2.5-11.5,3.7-18
          c1-5.5,1.6-10.9,2.2-15.7c0.1-1.1,0.3-2.3,0.4-3.4c0.8-7,1.1-13.9,1-20.6c0-1.3,0-2.5,0-3.7c0-6.5,0-14.6-3.3-22.7
          c-5.7-14-18.1-23.7-33.2-25.9c-6-0.9-10.8-1.3-15.2-1.3c-4.1,0-7.9,0.4-11.4,1.1c-13.4,2.9-21.4,11.8-25.6,16.5
          c-0.4,0.4-0.8,0.9-1.2,1.3c-0.4,0.4-1,1-1.7,1.6c-1.1,1-2.4,2.2-3.8,3.7c-0.9-4.8-2.8-9.4-6.6-13.6c-7.6-8.4-19.1-13.7-32.5-15.1
          c-1.5-0.2-3-0.2-4.5-0.2c-8.4,0-15.6,2.4-20.9,4.1c-0.7,0.2-1.5,0.5-2.2,0.7l-1.1,0.3c-6,1.9-15,4.7-22.2,11.6
          c-1.8-3.6-3.9-6.9-6.3-9.6c-9.8-11.2-25.6-13.8-34.2-14.3c-1.3-0.1-2.6-0.1-3.9-0.1c-4.6,0-8.7,0.5-12,0.8
          c-1.1,0.1-2.5,0.3-3.1,0.3c-2.3,0-5.1-0.3-8.1-0.6c-3.6-0.4-7.7-0.7-11.9-0.8c-6.7-0.1-13.3-0.2-20-0.3c-0.9,0-1.8,0-2.6,0
          c-2.8,0-5.6,0.1-8.5,0.1c-2.4,0.1-4.8,0.1-7.2,0.1c-0.6,0-2.6,0-3,0c-4.2,0-8,0.4-11.5,0.7c-2.6,0.2-5.1,0.5-7.2,0.5l-0.3,0
          c-0.8,0-2.9-0.2-4.8-0.3c-3.3-0.3-7.5-0.7-12-0.8c-1.1,0-2.3,0-3.4,0c-6.7,0-12.8,0.5-18.3,1.4c-5.6,1-22.7,4-31.6,19.9
          c-0.2,0.3-0.4,0.6-0.6,1c-1,1.6-2.3,3.8-3.6,6.5c-5.4-5.5-11.3-10-17.6-13.3c-5.4-2.9-10.7-4.4-15-5.7c-1.7-0.5-3.4-1-4.7-1.5
          c-3.1-1.2-31.7-10.5-41.5-11.8c-3.6-0.5-7.2-0.7-10.6-0.7c-6.6,0-12.9,0.9-18.9,2.6c-11.5,3.3-19.3,10.4-25.6,16l-0.1,0.1
          c-0.7,0.6-1.4,1.2-2.1,1.8c-4,3.4-9.4,8.1-14.5,16.7c-3.7,6.3-6.4,12.9-8.7,18.6l-0.6,1.4c-2.4,5.9-11.3,31.6-13,39.8
          c-1.3,6.2-2.6,13-3.3,20.1c-0.7,7.6-0.2,32.9,0.7,40.1c0.6,5.5,1.9,11.2,3,16.3c0.3,1.5,0.7,3,0.9,4.3c1.6,7.8,8.3,27.5,12.4,37.6
          c1.4,3.5,3.1,6.7,4.7,9.7c1.4,2.6,2.6,5.1,3.6,7.4c0.8,1.8,1.6,4.3,2.5,6.9c1.2,3.5,2.5,7.4,4.2,11.4c1.5,3.6,5.5,12.3,9.3,20.7
          c2.6,5.6,5.2,11.4,6.1,13.5c1.8,4.1,3.7,7.8,5.3,11.1c1.1,2.2,2.2,4.4,3,6.2c1.5,3.4,4.3,9.2,7.3,15.2c-2.9-0.5-5.9-0.8-8.9-0.8
          c-7.1,0-13,1.5-17.4,2.5c-0.6,0.1-1.2,0.3-1.8,0.4c-8.3,2-20.4,5.8-29.7,16.8c-0.8,1-1.6,2-2.3,2.9c-0.4-2-0.8-4-1.1-5.6
          c-0.3-2.2-0.5-4.7-0.8-7.4c-0.3-3.1-0.6-6.4-1.1-9.9c-0.3-2.3-1-6.4-1.8-11.5c-1.2-7.2-3.1-19.2-3.4-22.2c-0.5-5-1-10.6-1.5-17.4
          c-0.2-2.1-0.6-6.6-1-11.7c-0.8-8.9-1.7-20-2-23.6c-0.2-2.2-0.5-7-0.8-12.5c-0.7-11.5-1.2-19.9-1.6-23.6c-0.2-2.1-0.3-4.5-0.4-7
          c-0.2-3.4-0.4-7.3-0.8-11.3c-0.7-6.2-1.6-12.4-2.5-18.4c-0.6-4.3-1.5-8.4-2.3-12c-0.5-2.3-1-4.4-1.3-6.4c-1.2-6.5-6.4-30.4-8.2-37
          c-1.9-6.9-10.9-32-14.7-39.2c-3.6-7-8.2-15.7-16.3-22.6c-8.7-7.4-19.3-10.9-32.1-10.9c-0.4,0-0.8,0-1.2,0
          c-9.1,0.1-18.1,2.5-27.7,7.1c-7.4,3.6-16.9,9-24.3,19c-4.9,6.6-7.1,13.3-9,18.7c-0.6,1.6-1.1,3.2-1.6,4.4
          c-0.7,1.7-1.5,3.5-2.4,5.5c-1.9,4.2-4.1,8.9-5.8,14.3c-1.9,6.4-3.8,12.2-5.7,17.2c-0.3,0.9-0.7,1.9-1.1,3
          c-1.6,4.3-3.8,10.1-5.6,17c-1,3.7-1.8,7.5-2.6,11.2c-0.6,2.6-1.1,5.3-1.8,8.1c-1.4,5.9-2.7,11.1-4.1,17.7
          c-0.9,4.1-1.9,8.7-2.8,13.3c-0.5,2.3-1,4.7-1.4,6.8c-0.8,3.8-1.7,9-2.7,14.1c-0.7,3.9-1.4,7.9-1.9,10.4
          c-1.4,6.8-5.2,27.4-6.1,32.5c-0.5,2.8-1.1,5.7-1.8,8.4c-0.8,3.5-1.6,7.1-2.2,10.7c-0.9,5.5-2.1,16.3-3.2,25.9
          c-0.6,5.5-1.2,10.7-1.5,12.9c-0.3,2.1-0.6,4.3-0.9,6.6c-0.6,4.5-1.2,9.2-1.9,13.1c-0.6,3.2-1,6.1-1.4,8.9c-0.4,3-0.8,5.9-1.4,8.9
          c-1.2,6.3-5.5,32.3-6.5,39.2c-0.4,2.7-2.2,11.4-3.6,17.8c-1.8,8.3-2.8,13.5-3.3,16.5c-0.6,4-1.4,9.2-2.4,14.4
          c-0.7,3.6-1.4,7.3-2.3,11.2c-0.1-4.3-0.4-8.4-0.7-12.1c-0.3-3.8-1.4-12.7-2.7-23.9c-0.6-4.7-1-8.7-1.2-10.4
          c-0.8-7.9-2.7-29.2-2.8-33.3c-0.1-5.5,0.5-47.3,0.7-53c0.1-2.2,0.4-10.1,0.8-18.5c0.9-18.5,1.5-31.8,1.5-35.7
          c0-2.1,0.1-6.1,0.1-10.9c0.1-9.2,0.2-21.7,0.4-25.5c0.1-3.9,0-7.6-0.1-10.9c-0.1-2.7-0.2-5.3-0.1-7.4c0.1-3.6,0.6-13.5,1.1-22.3
          c0.3-6.2,0.6-11.9,0.7-14.4c0.2-3.8,0.1-7.2,0.1-10.6c0-2.8-0.1-5.4,0.1-7.8c0.2-4.3,0-9.7-0.2-15.9c0-0.4,0-0.9-0.1-1.4
          c4.5-0.4,11.8-0.8,15.4-0.9c4.6-0.2,7.3-0.4,9.2-0.5c1-0.1,2-0.2,3-0.3c6.4-0.5,13-1.1,19.3-3.5c9.2-3.5,21-12.4,24.7-35.2
          c1.4-8.4,1.3-17.1-0.2-25.8c-1.9-16.7-9.5-26.7-15.6-32.1c-7.2-6.4-16.3-10.6-28.7-13.2c-6.8-1.4-14.8-3-23.5-3l-0.6,0
          c-5.9,0-11.3,0.9-16.1,1.6c-1.8,0.3-3.6,0.5-5.4,0.8c-5.3,0.6-32.4,4.4-39.7,6c-2.6,0.6-6.7,1.6-11.5,2.7c-8.3,2-20.7,5-25.2,5.7
          c-4.6,0.8-8.7,2-12.4,3.1c-2.7,0.8-5.3,1.6-7.4,1.9c-5,0.9-35.1,7.1-42.6,9.6c-15.6,5.2-25.9,18.5-28.2,36.5
          c-1.1,8.9-2,22.2,3.9,35.2c8,17.7,25.8,20.5,33.5,21.7c0.7,0.1,1.4,0.2,2.2,0.4c4.3,0.7,9.8,1.6,16,1.6c1.6,0,3.2-0.1,4.8-0.2
          c1.9-0.2,4.3-0.2,6.7-0.2c0.8,0,1.6,0,2.4,0c0.2,9.5,0.3,27.2,0.2,30.2c-0.1,4-0.1,13-0.1,21.7c0,5.8,0,11.7,0,14.2
          c0,2.5-0.3,5.9-0.5,9.2c-0.3,4-0.6,8.2-0.6,12.1c0,2.9,0,9.7-0.1,16.8c0,8.3,0,17.6-0.1,20.9c-0.1,5.3-0.3,12.7-0.5,18.6l-0.1,3.2
          c-0.2,6.4-0.8,27.6-0.9,33.4c-0.1,3.8-0.1,13.2-0.2,22.4c0,5.5,0,10.5-0.1,12.7l0,1.2c-0.1,5.4-0.1,10.7-0.2,16.1
          c-0.1,5.6-0.9,28.6-1.1,34c-0.1,1.6-0.2,3.2-0.2,4.7c-0.2,4.2-0.4,8.1-0.7,11.9c-0.1,0.6-0.4,2.4-0.6,3.8c-0.7,3.6-1.5,8.6-2,14.2
          c-0.4,5.1-0.2,9.9,0,14.2c0.1,2,0.2,4,0.2,5.6c0,11.7,3,21.1,5.5,26.9c9.3,21.6,26,24,32.8,24c3.1,0,6.2-0.4,9.3-1.3l2.7-0.8
          c10.7-3,16.3-4.6,20.3-6.7c0.5-0.3,1-0.5,1.2-0.6c0.4,0,3.3,0.1,3.3,0.1c1.2,0,2.2,0.1,3.2,0.1c1.6,0.1,3.1,0.1,4.4,0.1
          c0,0,0,0,0,0c22.8,0,31.1-17.1,35.6-26.2c0.9-1.9,2.1-4.4,3.3-7.4c-0.2,4.8,0.1,9.5,1.2,13.9c3.7,14.3,9.1,20.6,14.3,25.8
          c11.4,11.4,29.6,12.3,34.9,12.3c2.3,0,4.5-0.2,6.5-0.5c10.5-1.6,45.6-8.2,59.1-28.2c6.6-9.8,8.6-30.6,9.2-46.4c0-0.3,0-0.6,0-1
          c0.2,0,0.3,0,0.5,0c3.6,0.3,8.7,0.6,14.3,0.7c-0.1,8.6-0.4,31.9-0.3,37.4c0,1.1,0,2.2,0,3.2c-0.1,5.3-0.2,12.5,2.5,20.1
          c3.9,11.1,11.4,19.9,21.7,25.4c9.8,5.3,22,5.8,26.8,5.8c2.6,0,5.1-0.1,7.6-0.4c12.4-1.4,24.8-6.2,32.3-12.7
          c9.9-8.4,13.7-17.2,16.3-25.4c1.8-5.5,3.4-18.5,4.3-28.6c3,5.4,5.9,10.3,8.1,13.1c4.6,6,9.4,11.4,14.7,16.3
          c6,5.5,12.4,9.7,17.6,13.1c5.6,3.6,31,15.7,43.5,17.2c4.1,0.5,9.1,1,14.6,1c3.5,0,6.7-0.2,10-0.6c9-1.2,17.1-4.1,23.7-6.8
          c8.9-3.6,16.1-8.6,21.3-12.6c7.9-6,25.6-27.7,30.1-37c0.6-1.2,1.2-2.5,1.9-3.7c2.5-4.9,5.4-10.5,7.3-17c0.7-2.3,1.9-7.7,3.1-14.1
          c0.3,4.7,1,8.8,1.7,12.3c1,5.5,2.6,14.6,8.8,24.1c4.1,6.2,14,16.6,35,16.6c2.5,0,5-0.1,7.6-0.4c7-0.8,13.4-2.7,19-4.3
          c1.2-0.4,2.4-0.7,3.6-1c3.6-1,6.7-2,9.5-3c1.5-0.5,3.4-1.1,4.6-1.5c12.4-0.7,21-4.9,26.8-9.9c0.4,0.8,0.7,1.6,1,2.2
          c3.6,7.2,8.1,14.7,12.2,20.4c4.8,6.7,10,11.6,14.6,16l1.1,1.1c5.9,5.6,12.4,11.3,20.1,15.5c12.1,6.6,34.2,14,44.7,14.9
          c2.9,0.2,6.1,0.4,9.6,0.4c11.8,0,29-1.6,37.5-4.5c18-6.2,31.3-20,37-25.8l0.6-0.6c3.3-3.4,9.6-10.1,14.7-16.8
          c5.7-7.6,17.5-30.8,19.5-35.4c3.5-8,5.8-16.4,7.6-23.6c1.9-7.9,3.2-15.3,4-22.8c0.6-6.5,0.9-13.3,1.2-21.4c0.1-4,0-7.8-0.2-11.6
          c-0.1-3-0.2-5.8-0.2-8.6c0-1.7,0.1-3.5,0.1-5.3c0.2-4.5,0.3-9.5,0-15c-0.2-3.2-0.6-6.1-0.9-8.7c-0.4-2.9-0.7-5.6-0.7-7.3
          c0.1-3,0-6.2-0.2-9.2c0.1-0.1,0.2-0.2,0.4-0.2c1.8-1.2,3.4-2.4,4.8-3.4c3-2.1,16.5-9.8,23.1-13.5c4.3-2.5,7.7-4.4,9.7-5.6
          c6.4-3.8,11.6-8.1,16.2-11.9c0.9-0.7,1.8-1.5,2.7-2.2c1-0.8,2.5-1.9,4.2-3.1c3.1-2.2,6.8-4.9,10.7-8.2c1.9-1.6,24.9-23.6,30.3-35
          c1.6-3.5,2.6-6.9,3.2-10.2L1125,404.7z M698.2,332.8c-0.1,2.7-0.4,6.2-0.7,9.6c-0.3,3.1-0.5,6.2-0.7,9c-0.2,2.9-1,11.7-1.6,18.8
          c-0.6,6.1-1.1,11.9-1.3,14.5c-0.2,3-0.4,6.2-0.6,9.4c-0.2,2.9-0.3,5.8-0.5,8.6c-0.3,3.7-0.3,7.2-0.4,10.2c0,2.6-0.1,5-0.2,7.2
          c-0.2,2.1-0.5,4.9-0.9,7.9c-0.5,3.9-1,7.9-1.2,11.7c-0.1,2.2-1,11.9-1.8,20.4c-1.5,16.7-2.4,27.1-2.6,30.7
          c-0.1,3.5-0.1,9.6,0,19.2c0.1,5.7,0.1,12.8,0.1,15c-0.1,3.2-0.2,6.6-0.3,10c-0.1,3-0.1,6-0.2,8.7c-0.1,2.7-0.7,11.3-1.2,18.1
          c-0.6,8.6-1,14-1.2,16.8c0,0.7-0.1,1.8-0.2,3.2c-0.4-3-0.8-5.9-1.2-8.7l-0.1-0.5c-0.9-6.1-2.9-11-4.5-15c-0.6-1.5-1.3-3.3-1.5-4.1
          c-1.4-5.9-3.1-12-5.2-18.6c-1.6-5.2-3.5-10.3-5.3-15.1l-1-2.7c-1.6-4.4-3.4-8.7-5.1-12.8c-0.6-1.5-1.2-2.9-1.8-4.4
          c-1.5-3.7-11.2-27-14.2-33.5c-2.5-5.3-5-10.7-7.6-16.2c-2.5-5.3-5-10.6-7.6-16c-0.5-1.1-1.2-3.1-1.8-5.1c-1-3.3-2.3-7.3-4.3-11.5
          c-1.1-2.4-2.3-4.8-3.4-7.1c-1.4-2.9-2.8-5.7-4.1-8.6c-1.5-3.2-3-6.1-4.4-8.7c-1.3-2.4-2.5-4.7-3.5-6.8c-0.9-2.1-1.9-4.6-2.8-7.2
          c-1-2.7-2.2-5.8-3.5-8.9c-1-2.4-2.1-5.1-3.1-7.9c0.1,0.1,0.2,0.1,0.3,0.2c5.1,3.4,11.5,7.5,20.1,8.2c1.1,0.1,2.2,0.1,3.3,0.1
          c14.6,0,24.5-7.5,27.7-9.9c8.1-6.2,14.1-14.7,17.2-24.6l0.5-1.5c1.7-5.3,4-12.6,4-20.9c0-2.7,0-5.4,0.1-8.2c0-2.9,0.1-5.9,0.1-8.8
          c0-2.8-0.2-5.4-0.3-7.9c4.3,1.2,9.1,1.8,14.3,1.8c3.1,0,6.5-0.2,10.7-0.6c0,0,2.6-0.3,3.5-0.3c0.1,1.8,0.1,3.9,0.2,6
          c0,1.2,0.1,2.5,0.1,3.7C698.5,304.1,698.5,327.4,698.2,332.8z M870.5,466.9c-3.5,1.6-7,3.1-10.5,4.7c-1.1,0.5-2.2,1-3.3,1.5
          c-4.6,2.1-9.4,4.3-14.2,7c-5.5,3-9.7,6.3-13.5,9.3c-1.1,0.8-2.1,1.6-3.2,2.5c-5.6,4.2-14.5,11.9-19.8,17.7
          c-0.1,0.1-0.2,0.3-0.3,0.4c0.1-2.8,0.2-5.9,0.1-9.3c-0.5-23.8-0.6-32.1-0.6-34.9c0-3.3-0.2-6.8-0.3-10.3c-0.1-3.4-0.3-6.9-0.3-9.7
          c0-2.4,0.1-5.4,0.3-8.5c0.2-3.6,0.3-7.4,0.3-11.1c0-2.9,0-5.4,0.1-8c0.1-2.8,0.1-5.7,0.1-9c0-6-0.3-26.7-0.5-32.7
          c-0.1-3.6-0.2-8-0.4-12.3c-0.1-2.7-0.2-5.4-0.3-7.6c-0.1-3.4-0.3-6.6-0.5-9.8c-0.1-2.6-0.3-5.1-0.4-7.7c-0.1-3.2-0.5-8.6-0.8-14.3
          c-0.3-5-0.7-10.2-0.7-12.7c-0.1-2.3-0.3-6.4-0.6-11c-0.2-4.1-0.5-8.5-0.6-11.1c0-0.7-0.1-1.5-0.1-2.4c1.8-0.2,3.5-0.4,5.2-0.6
          c2.1-0.2,4-0.5,5.4-0.6c1.3-0.1,2.7-0.1,3.9-0.1c5.7-0.1,13.4-0.2,21.3-3.6c-1.5,9.4-3.7,23.7-4.3,28.3c-0.7,4.9-0.6,9.3-0.5,12.9
          c0,2,0.1,4.1-0.1,5.3c-0.2,1.9-0.5,3.9-0.7,5.8c-0.5,4-1,8.2-1.4,12.4c-0.2,2-0.5,4.2-0.9,6.6c-0.5,3.5-1.1,7.4-1.4,11.6
          c-0.3,4.6-1.2,31.2-0.5,39c1,10.5,3.6,19.9,7.6,27c3.2,5.7,7.2,12.3,12.3,18c5.8,6.3,11.1,10,17.3,13.5c2.1,1.2,4.5,2.4,7.3,3.6
          C870.9,466.7,870.7,466.8,870.5,466.9z M927.3,580.5c-0.5,5.3-1.1,10.7-2.3,14.6c-1.2,3.9-2.9,8.1-5.9,14
          c-1.3,2.7-2.8,4.4-3.7,5.2c-0.6,0.2-1.9,0.5-4.7,0.8c-0.2,0-0.4,0-0.7,0c-1.2,0-3.4-0.2-6.9-1.3c-2.1-0.6-3.5-1.1-4.4-1.5
          c-0.2-0.7-1.1-0.3-1.4-1.8l-0.5-1.3c-0.3-1.7-0.3-2,0-6.6c2-7.2,7.9-17.9,10.1-20.9l0.3-0.4c2.3-2.5,5.9-6.3,9.3-8.9l2.6-2
          c2.8-2.2,5.3-4.1,8.1-6c0,0.1,0,0.1,0,0.2c-0.2,3.5,0,6.5,0.2,9.1C927.3,576.4,927.5,578.4,927.3,580.5z"/>
        <path d="M282,246.6c-0.7-7.2-3.1-13.1-7.5-17c-4.3-3.9-10.6-6-17.3-7.5c-5.7-1.2-12.1-2.5-18.7-2.4c-6.1,0-12.4,1.4-18.8,2.2
          c-6.3,0.7-31.7,4.3-37.3,5.6c-7.6,1.7-30.1,7.4-37.9,8.7c-6.3,1.1-12.8,3.7-19.6,5c-6.8,1.2-34.2,7.1-39.1,8.7
          c-6.7,2.2-10.3,7.8-11.4,16.1c-0.9,6.8-1.3,14.8,1.9,21.8c2.4,5.2,8.3,6.4,14.7,7.4c5.2,0.8,11,2.1,17,1.6
          c5.9-0.5,12-0.1,17.2-0.6c8.2-0.8,16.1-2.5,17.2-0.6c1.1,1.8,0.9,11.1,1.4,19.1c0.4,6,0.5,33.4,0.4,37.6c-0.2,6.3,0,29.3-0.1,35.6
          c-0.1,6.3-1,14.9-1.1,21.2c-0.1,6.3,0,31.6-0.1,37.8c-0.1,6.3-0.4,15.7-0.6,21.9c-0.2,6.3-0.8,27.5-0.9,33.3
          c-0.1,5.8-0.2,29.1-0.2,34.9c-0.1,5.8-0.1,11.6-0.2,17.4c-0.1,5.8-0.9,29-1.2,34.8c-0.3,5.8-0.5,11.6-1,17.4
          c-0.3,3.9-2,10.4-2.6,17.7c-0.4,5.8,0.2,12.2,0.2,17.9c0,7.3,1.8,13.3,3.5,17.1c2.6,6,6.5,10.2,12.6,8.5
          c5.9-1.7,15.8-4.3,18.5-5.7c7.5-3.8,10.2-3.3,16.1-3.2c11.2,0.2,13.4,2.5,20.5-12.1c2.3-4.6,4.8-10.2,5.2-16.3
          c0.4-5.4,0-11.4-0.5-17.2c-0.5-5.7-3.4-28.9-3.9-33.8c-0.6-6-2.9-29.6-2.9-35.6c-0.1-6,0.5-48.2,0.7-54.2c0.2-6,2.3-47.6,2.4-53.7
          c0.1-6.2,0.3-30.7,0.5-36.9c0.2-6.2-0.5-12.3-0.2-18.5c0.2-6.1,1.5-30.7,1.8-36.9c0.3-6.1-0.1-12.2,0.2-18.4
          c0.4-8.3-1.4-28-0.1-35.8c0.6-3.6,13.2-3.2,15.6-4.1c3.6-1.4,26.3-2,31.4-2.5c6.2-0.6,12.1-0.8,15.8-2.2c5.1-1.9,7.8-8.4,9-15.9
          C283.1,259.7,283.2,253.5,282,246.6z"/>
        <path d="M392.2,553.2c-5.5,1.4-27.3,0.7-33.2,0.1c-8-0.7-17,0.4-17.8-2c-1-2.7,1.9-14.5,2.1-21.5c0.2-6.7,1.6-22.9,2.7-31.6
          c0.7-5.4,0.7-11.8,1.6-18.6c0.8-5.8,3-30.9,3.9-37.1c0.9-6.1,2.6-12.2,3.5-18.3c1-6.3,2-12.5,3-18.4c1.1-6.5,5.9-30.6,7.1-35.8
          c2.4-10,5.6-20.2,7.6-20c1.6,0.1,3.1,10.3,4.3,21.3c0.6,6.2,4.1,48.8,5,58.6c0.5,5.6,2.4,31.5,2.9,38c0.5,6.3,2.1,12.6,2.6,18.9
          c0.5,6.5,1.5,12.6,1.9,18.7c0.5,6.9,1.2,12.8,1.5,18.4C391.4,532.8,395.2,552.5,392.2,553.2z M460.3,639.4
          c0.3-6-1.7-11.9-1.9-17.7c-0.2-6.1,0-12-0.6-17.7c-0.7-6.2-2.5-30.1-3.5-35.4c-1.2-7-4.7-29-5.4-34.8c-0.8-5.8-2.4-11.5-3.2-17.2
          c-0.9-5.8-1-11.7-1.9-17.4c-0.8-5.8-4.8-28.9-5.4-34.9c-0.6-5.9-1.1-11.8-1.6-17.8c-0.5-5.9-2.5-29.6-3-35.5
          c-0.4-5.9-1.7-29.7-2.3-35.6c-0.6-5.9-0.5-11.9-1.2-17.8c-0.7-5.9-1.5-11.8-2.4-17.7c-0.8-5.9-2.4-11.7-3.5-17.5
          c-1.1-5.9-6.1-29-7.7-34.8c-1.7-6-10.1-29.3-12.8-34.4c-3.1-6-6-11.4-10.3-15c-4.7-4-10.2-5.2-16.9-5.1
          c-5.2,0.1-10.6,1.4-17.3,4.6c-5.9,2.9-11.3,6.2-15.2,11.4c-3.7,5-5,11.6-7.5,17.7c-2.4,5.9-5.6,11.6-7.5,17.8
          c-2,6.4-4,12.7-6,18.4c-1.6,4.4-4.1,10.5-6.1,18.1c-1.5,5.6-2.6,11.9-4.2,18.6c-1.4,5.9-2.6,10.9-4.1,17.4
          c-1.4,6.1-2.9,13.5-4.2,19.8c-1.3,6.2-3.3,18-4.5,24.2c-1.3,6.3-4.9,26.1-6,32c-1.3,6.9-2.9,13.1-3.8,18.7
          c-1.3,8.1-3.8,31.8-4.7,38.2c-0.9,6.4-1.8,14-2.9,20.5c-1.1,6.4-1.7,11.7-2.8,18.1c-1.2,6.4-5.4,32-6.4,38.4
          c-0.9,6.5-6.1,29.3-6.9,34.4c-0.8,5.1-1.6,10.2-2.6,15.3c-1,5.1-2,10.1-3.1,15.3c-1.4,6.5-4.5,19.3-2.8,26
          c2.3,8.9,4.8,11.4,7.8,14.5c4.2,4.2,15.5,5.6,20.2,4.9c9.8-1.5,35.5-7.6,42.2-17.6c3.1-4.6,4.7-27.8,4.9-33.3
          c0.2-6,1-22.4,2.1-23.9c1.3-1.9,8.9-1.3,20.8-1.2c2.6,0,11,0.9,19.2,0.9c8.4,0,21-0.2,21.7,0.9c1.1,1.8,0.9,14.8,0.7,23.2
          c-0.1,8.6-0.4,32.7-0.3,37.8c0.1,5.6-0.5,11,1.1,15.6c1.7,4.9,4.8,8.9,10,11.7c3.8,2.1,12,3.5,19.9,2.6c7.8-0.9,15.5-4,18.9-6.9
          c5.5-4.7,7.1-9,8.8-14.1C457.7,668.8,460.1,646.2,460.3,639.4z"/>
        <path d="M563.6,347.5c-1.7-4.5-4.9-12.7-5.7-20.4c-0.6-5.9-0.3-24.6,1.5-30.7c1.8-6.1,6-18.3,9.6-23.6c3.5-5.2,7.1-9,12.6-12.7
          c6.3-4.2,12.9-1.2,15.8,4.1c3.9,7.3,1.4,27.5,0.1,33.6c-1.2,5.7-5.2,11.2-5.7,16.7c-0.6,6.2,2.4,11.6,5.1,16.5
          c1.3,2.3,5.1,5.6,9.9,8.5c4.6,2.8,9.1,6.4,12.1,6.6c6.1,0.5,10.5-2.1,14.1-4.8c4-3,6.8-7,8.4-12.2c1.5-4.7,3.3-10,3.3-15.1
          c0-5.6,0.1-11.3,0.1-17.1c0-5.7-0.9-11.4-1.2-17.1c-0.3-5.8,0-11.6-0.9-17.1c-0.9-5.7-2.9-11.3-4.8-16.5
          c-2.3-6.1-5.8-10.7-9.4-14.7c-4-4.4-8.4-8.1-13.5-10.9c-4.9-2.7-10.8-3.7-16.4-5.7c-5.3-1.9-30.2-9.7-36.2-10.5
          c-6.8-0.9-13.4-0.6-19.5,1.1c-6,1.7-10.8,6.1-15.9,10.7c-4.4,3.9-8.1,6.3-11.7,12.6c-3.1,5.3-5.4,11.1-7.8,17
          c-2.4,5.7-10.4,29.6-11.7,35.5c-1.2,5.8-2.3,11.6-2.9,17.3c-0.5,5.8,0,29.2,0.6,34.9c0.7,5.7,2.4,12.8,3.6,18.4
          c1.2,5.7,7.1,23.6,11.1,33.2c2.1,5.1,5.6,10.6,8.3,17c2.3,5.5,4.1,12.2,6.6,18.2c2.4,5.7,12.9,28.4,15.4,34
          c2.7,6.3,5.9,11.9,8.2,17c3.4,7.6,14.3,29.6,17.6,35.7c3.3,6.2,5.4,13,8.1,19.4c2.7,6.4,5,12.9,6.7,19.8c1,4.4,7,27,7.4,32.6
          c0.5,5.7-0.8,24.5-6.2,32.3c-4.1,5.9-6.7,8.6-13.5,8.1c-8.2-0.6-13.3-6.8-15.6-13.4c-2.8-8.1-1.8-17.9-1.8-21
          c0-4.2,0.9-10.7,0.7-17.9c-0.1-5.7-1.7-11.8-2.8-17.7c-1.1-6.2-3.1-11.7-6.1-16.5c-3.2-5.1-7-9.7-13.4-11.2
          c-7.2-1.6-13.4,0.4-19,1.7c-6.7,1.6-12.3,3.8-16.4,8.6c-4,4.7-7.7,10.4-8.6,17.3c0,0,0.8,29.2,1.7,35.8c0.7,5.5,2.8,11.2,4.3,17.3
          c1.3,5.6,3.3,11.1,5.5,16.9c2,5.5,12.8,26.3,16.5,31.2c3.5,4.6,7.4,9,11.9,13.1c4.2,3.9,9.1,7.1,14.3,10.4
          c4.8,3.1,26.4,12.6,33,13.4c5.9,0.7,12,1.2,18.4,0.4c6-0.8,11.7-2.8,17.6-5.2c5.5-2.2,10.5-5.5,15.6-9.4
          c4.7-3.6,19.9-22.2,22.8-28.1c2.6-5.4,6.1-11.2,7.7-16.7c1.7-5.7,5.8-28.2,5.6-34.1c-0.2-5.8-1-11.8-1.9-17.7
          c-0.8-5.7-4.2-11.1-5.6-16.9c-1.3-5.6-2.9-11.2-4.7-17c-1.7-5.5-3.8-11-5.9-16.6c-2-5.4-4.3-10.8-6.6-16.3
          c-2.2-5.4-11.3-27.1-13.8-32.5c-2.4-5.3-4.9-10.6-7.5-16c-2.5-5.3-5-10.6-7.6-16c-2.5-5.3-3.6-11.3-6.1-16.7
          c-2.5-5.3-5.2-10.5-7.7-15.9c-2.5-5.3-5.6-10.4-8-15.8c-2.4-5.3-4.2-11-6.5-16.4c-2.3-5.4-4.2-11-6.5-16.5
          C568.4,358.3,565.7,353.1,563.6,347.5z"/>
        <path d="M836.4,245.7c1.6-3.9,3.7-10.1,3.2-16c-0.5-6.1-3.2-12.3-5.9-15.4c-2.9-3.3-10-5.4-17-5.9c-6.7-0.4-13.5,1.1-17.6,1.1
          c-6.8-0.1-13.7-1.3-20.3-1.4c-6.7-0.1-13.4-0.2-20.1-0.3c-6.6-0.1-13.3,0.4-20.1,0.2c-6.6-0.1-13.2,1.2-20.1,1.1
          c-3.9-0.1-10.2-1-17.1-1.1c-5.7-0.1-11.6,0.1-16.9,1c-6.5,1.1-11.7,3.1-14.2,7.6c-1.7,3-5.4,8.3-6.4,14.9
          c-0.8,5.1,1.1,11.1,1.8,16.4c0.7,5.6,2.2,10.5,5.6,14.4c3.7,4.2,12,4.1,20.7,3.2c8.2-0.8,25.2-2.6,27.8-1
          c1.8,1.1,0.9,8.4,2.3,16.6c0.8,4.9,0.9,10.9,1.1,17.7c0.2,5.4,0.1,29.1-0.2,35.3c-0.3,5.6-1.1,13-1.4,19.1
          c-0.4,5.7-2.4,27.7-2.9,33.6c-0.4,5.8-0.7,12-1.1,18c-0.4,5.8-0.2,11.4-0.6,17.3c-0.4,5.8-1.7,13.5-2.1,19.4
          c-0.4,5.8-4.1,44.8-4.3,50.6c-0.3,5.9,0.3,28.3,0.1,34c-0.2,6-0.3,13-0.5,18.7c-0.2,6-2.2,29.5-2.4,35.1
          c-0.3,6.2-2.3,28.5-2.8,33.1c-0.6,5.7,0.3,10.9,1.2,15.9c0.8,4.5,1.7,9.7,5.2,15c3.2,4.8,11.1,5.9,19,5.1
          c6.1-0.7,12.5-2.9,18.7-4.6c8-2.2,14.7-5.2,18.5-5.3c8.8-0.3,13.9-3.4,16.4-8.2c2.5-4.7,3.2-11,2.8-18.5c-0.3-5.9-2-12.3-2.5-19.1
          c-0.4-6.2-0.8-12.3-1.2-18.4c-0.4-6.2-0.6-12.3-0.9-18.4c-0.3-6.2-0.9-12.3-1.1-18.4c-0.2-6.1-0.4-12.3-0.6-18.4
          c-0.2-6.1,0.5-12.3,0.3-18.4c-0.1-6.1-0.6-29-0.6-35.3c0-6-0.5-13.8-0.5-20c0-6,0.6-13.4,0.6-19.7c0-6,0.2-10.7,0.2-17
          c0-5.8-0.3-26.3-0.5-32.1c-0.1-5.8-0.5-14-0.7-19.7c-0.2-5.8-0.7-11.6-0.9-17.4c-0.2-5.7-1.4-21.2-1.6-27c-0.2-4.1-1-16.5-1.2-22
          c-0.2-5.5-1.8-21-1.8-25.2c0-3.5,8.7-2.4,18.8-2.7c6.2-0.2,11.9-1.3,17-1.6c6.5-0.3,12.5,0.4,17-1.8
          C831.5,257.4,834.4,252.3,836.4,245.7z"/>
        <path d="M952.7,582.8c-0.7,7.6-1.6,13.8-3.2,19.4c-1.8,5.9-4.3,11.6-7.6,18.1c-2.9,5.7-6.9,10.7-11.2,14
          c-4.7,3.6-10.9,4.8-16.9,5.5c-5.3,0.5-11.1-0.3-17.3-2.2c-6.1-1.9-11.8-4-15.4-7.7c-3.9-4.2-5.8-10.2-6.9-15.8
          c-1.1-5.3-2.5-11.3-1-17c3-11.6,11.9-27.7,15.8-31.8c2.8-3,7.6-8.2,13-12.4c4.3-3.3,8-6.3,12.9-9.5c4.9-3.3,10.3-6.4,14.9-8.9
          c6.6-3.6,21.3-14.7,22.4-11.5c2.3,7.1-0.7,8.3,0.6,25.8c0.3,4.6,0.3,9.6,0,17.2C952.3,571,953.4,576,952.7,582.8z M1099.4,396.4
          c-2.5-6.6-5.3-10.9-8.3-14.2c-4.6-4.9-8.6-6-14.2-5.3c-4.9,0.6-9.6,2.9-15.1,6.3c-4.5,2.8-9.6,5.3-14.3,9.1
          c-4.3,3.5-8.8,7.1-13.1,10.8c-4.4,3.8-8.1,8.6-12.4,11.9c-5,3.8-10.5,6.3-15.1,8.3c-8,3.5-16.4,8.4-16.4,8.4
          c-2.1-1.5,0.1-16.9,0.9-22.1c0.7-4.5,1.7-12.1,3.2-19.8c1.1-5.9,2.4-11.1,3.7-17.5c1.3-6.5,2.8-13.1,4-19
          c1.6-7.7,2.5-13.8,3.5-18.2c1-4.3,2-10.2,3.7-16.9c1.3-5.2,2.3-11,3.7-17c1.3-5.5,2.5-11.3,3.5-17c1.1-5.8,1.6-11.8,2.3-17.4
          c0.7-6.1,1-11.8,0.9-17.2c-0.1-6.6,0.4-13-1.5-17.6c-2.4-5.9-7.7-9.8-13.9-10.7c-6.8-1-12.8-1.5-17.7-0.5
          c-6.4,1.4-10.1,6.4-13.8,10.4c-3.8,4.1-7.6,6-9.4,11.8c-1.7,5.3-0.8,9.2-1.5,15.1c-0.7,5.9-1.8,11.5-2.1,17
          c-0.3,6.4-1.2,19.3-1.8,25.2c-0.6,5.8-3.7,21.7-4.7,27.5c-1,5.8-1.9,23.4-3.3,31.3c-0.8,4.6-1.5,21.9-5.9,27.9
          c-3.9,5.3-10.6,7.8-18.4,8.2c-7.6,0.3-13.1-3.6-18.4-7.7c-4.1-3.1-4.2-15.9-4.5-24.5c-0.2-6.1,1.2-13,2-20
          c0.8-6.9,0.8-13.9,2-20.1c1.6-8.6,2.4-16,3.1-20c0.6-3.7,5.2-26.5,6.4-32.1c1.2-5.3,3.7-10.5,4.8-15.7c1.2-5.8,1.7-11.7,2.4-16.5
          c1.3-8.4,2-14.4,0.6-15.9c-3.4-3.8-9.7-6.3-16.6-7.1c-5.9-0.6-12,1.8-17.6,3.6c-6.3,2-12.7,3.7-15.4,8.8
          c-1.1,2.1-3.6,8.2-5.5,16.9c-1.1,5-5.4,28.5-6.4,34.6c-0.9,5.6-4.6,28.9-5.4,34.8c-0.8,5.8,0.2,11.9-0.5,17.7
          c-0.7,5.9-1.5,11.8-2,17.6c-0.5,6-1.8,11.7-2.2,17.4c-0.4,6.2-1.1,30.1-0.6,35.3c0.7,7.5,2.5,13.5,4.6,17.1
          c2.7,4.9,5.7,9.7,9,13.4c3.7,4,6.7,6.1,11.2,8.6c4.5,2.6,11.4,5.4,16.8,6.3c1.2,0.2,7.8,1.5,16.8,3c5.7,0.9,10.9,1,16.6,2
          c9.8,1.6,25.6-0.6,25.6-0.9c0.2,1-11.5,8.9-19.9,13.2c-4.6,2.3-9.8,4.9-15.1,7.5c-5.2,2.5-10.6,4.9-15.3,7.1
          c-7.5,3.5-13.3,6.5-15.2,7.3c-5.4,2.4-10.7,4.8-16,7.2c-5.4,2.5-10.9,4.9-16,7.7c-5.1,2.8-9.2,6.4-13.9,9.9
          c-4.6,3.5-12.3,10.1-16.3,14.5c-4.2,4.6-13.1,22.6-14.6,28.7c-1.5,5.9-2.7,12-3.2,18.3c-0.5,6.1-1.9,12.5-1.5,18.8
          c0.4,6.2,1.8,12.5,3,18.6c1.3,6.1,9.2,28.8,11.9,34.2c2.8,5.7,6.7,12.2,10.2,17.2c3.7,5.2,8.2,9.3,12.6,13.4
          c4.6,4.4,9.6,8.8,14.9,11.8c9.9,5.4,28.8,11.4,34.9,11.9c12.3,1.1,31.5-1,36.9-2.9c12.9-4.5,23.7-16,27.8-20.3
          c4.1-4.2,9.1-9.7,12.6-14.5c3.6-4.8,14.3-25.3,16.6-30.5c2.8-6.3,4.7-13.3,6.2-19.5c1.5-6.2,2.7-12.5,3.4-19.3
          c0.6-6.1,0.8-12.5,1-19.6c0.2-6.1-0.4-12.6-0.4-19.7c0-5.9,0.6-12.2,0.2-18.4c-0.4-6.2-1.8-12.4-1.7-18.4
          c0.2-6.4-1.2-16.9-0.6-19.2c0.9-3.4,10.3-9,15.7-13c5.4-4,29.1-17,34.8-20.4c5.9-3.5,10.9-8,16-12.1c3.7-3,9.2-6.5,14.3-10.9
          c4.4-3.7,21.6-21.7,23.9-26.6c2.7-5.7,1.1-11.8-0.9-17.4"/>
      </g>
    </g>
  </svg>
);