/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import LinkWithTracking from '../LinkWithTracking';
import { CaretRightIcon } from '@buzzfeed/react-components';
import { useMediaQuery, useTrackingContext } from '../../hooks';
import styles from './breakingBar.module.scss';

export const BreakingBar = ({ text = '', link = '', label = '' }) => {
  const screenSize = useMediaQuery();
  const trackingData = {
    subunit_name: 'breaking_bar',
    subunit_type: 'component',
    item_name: 'breaking', // for now since only US edition
    item_type: 'button',
    unit_type: 'nav',
    unit_name: 'main',
    target_content_type: 'url',
    target_content_id: link || '', // we don't know buzzID if internal, should be external
  };

  // change color when things aren't breaking
  const containerStyles = label.toUpperCase() === 'BREAKING' ? 
    styles.container : `${styles.container} ${styles.announcement}`;

  return (
    <div className={containerStyles}>
      <div className={styles.contentWrapper}>
      <div className={styles.label}> {label} </div>
      {link.length > 0 ? (
        <LinkWithTracking
          className={styles.navLink}
          href={link}
          commonTrackingData={trackingData}
        >
          {text}
          <CaretRightIcon className={`${screenSize.isMobile ? 'svg-6' : 'svg-5 xs-mb05'} svg-white xs-ml1`} />
        </LinkWithTracking>
      ) : (
        <span className={styles.navText}>{text}</span>
      )}
      </div>
    </div>
  );
};

BreakingBar.propTypes = {
  tips: PropTypes.string,
};

export default BreakingBar;
