/* eslint-disable camelcase */
import { SubunitTrackingProvider } from '../../contexts';
import BrandedBox from '../BrandedBox';
import Heading from '../Heading';
import Carousel from '../Carousel';
import { Feed } from '../Feed';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import styles from './quizzes.module.scss';
import { QuizBadge } from '../../icons/QuizBadge';

export const Quizzes = ({ data }) => {
  const screenSize = useMediaQuery();

  const trackingCommon = {
    subunit_name: 'quizzes',
    subunit_type: 'package',
    position_in_unit: 7,
  };

  const QuizHeading = (
    <div className="xs-flex xs-flex-align-center">
      <QuizBadge className={styles.quizBadge} />
      <h2 className={styles.heading}> Top Quizzes </h2>
    </div>
  );

  // mobile becomes a carousel
  if (screenSize.isMobile) {
    return (
      <SubunitTrackingProvider value={trackingCommon}>
        <Heading className="xs-mb1">{QuizHeading}</Heading>
        <Carousel
          items={data.items.slice(0, 15)}
          color="yellow"
          seeMoreLink={data.typeInfo}
        />
      </SubunitTrackingProvider>
    );
  } else {
    return (
      <SubunitTrackingProvider value={trackingCommon}>
        <BrandedBox
          heading={QuizHeading}
          color="yellow"
          className="xs-pt4 xs-mb6 md-mb0"
        >
          <Feed
            items={data.items.slice(0, 3)}
            columns={3}
            typeInfo={data.typeInfo}
            hasRightText={screenSize.isMobile}
            hasSeeMoreLink
            lazyLoadThumbnails={true}
          />
        </BrandedBox>
      </SubunitTrackingProvider>
    );
  }
};

export default Quizzes;
