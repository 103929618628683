/* eslint-disable camelcase */
import React from 'react';
import { SubunitTrackingProvider } from '../../contexts';
import { useMediaQuery } from '../../hooks';
import BrandedBox from '../BrandedBox';
import Heading from '../Heading';
import Carousel from '../Carousel';
import { Feed } from '../Feed';
import FeedHeading from '../Feed/FeedHeading';
import { ShoppingLogo, ShoppingLogoIcon } from './ShoppingLogo';
import styles from './shoppingPackage.module.scss';

export const ShoppingPackage = ({ name, data }) => {
  const screenSize = useMediaQuery();

  const trackingCommon = {
    subunit_name: 'shopping',
    subunit_type: 'package',
    position_in_unit: 5,
  };
  
  const shoppingTitleRegex = new RegExp('(Shopping)( |[🛍️🛒])*');
  const hasBasicTitle = shoppingTitleRegex.test(name);

  // mobile becomes a carousel
  if (screenSize.isMobile) {
    return (
      <SubunitTrackingProvider value={trackingCommon}>
        <Heading>
          <div className="xs-flex xs-flex-align-center">
            <ShoppingLogoIcon className={styles.shoppingIcon} />
            <h2>BuzzFeed Shopping</h2>
          </div>
        </Heading>
        <Carousel
          items={data.items.slice(0, 15)}
          color="peach"
          seeMoreLink={data.typeInfo}
        />
      </SubunitTrackingProvider>
    );
  } else {
    return (
      <SubunitTrackingProvider value={trackingCommon}>
        <BrandedBox
          heading={<ShoppingLogo />}
          color="peach"
          className="xs-pt5 xs-mb6 md-mb0"
        >
          <h2 className="screen-reader-only"> BuzzFeed Shopping </h2>
          <div className="xs-flex xs-flex-column md-flex-row md-flex-wrap md-flex-align-center">
            {/* does not display default package title */}
            {!hasBasicTitle && (
              <FeedHeading heading={name} isSubheading />
            )}
          </div>
          <Feed
            items={data.items.slice(0, 3)}
            columns={3}
            typeInfo={data.typeInfo}
            hasRightText={screenSize.isMobile}
            hasSeeMoreLink
            lazyLoadThumbnails={true}
          />
        </BrandedBox>
      </SubunitTrackingProvider>
    );
  }
};

export default ShoppingPackage;
