import React, { useContext, useEffect, useState, useMemo } from 'react';
import { XIcon } from '@buzzfeed/react-components';
import { getUserGeoCountry } from '@buzzfeed/bf-utils/lib/localization';
import { useTrackingContext } from '../../hooks/tracking/useTrackingContext';
import { ABeagleContext } from '../../contexts';

import shoppy from './images/shoppy.svg';
import arrowIcon from './images/arrowIcon.svg';
import hat from './images/hat.png';
import backgroundImg from './images/background.png';
import backgroundClosedImg from './images/mobileBackground.png';
import styles from './styles.module.scss';

export function ShoppyClippy () {
  const { trackContentAction, trackInternalLink, trackImpression } = useTrackingContext();
  const { getFeatureFlagValue } = useContext(ABeagleContext);
  const showShoppy = getFeatureFlagValue('shoppybot-clippy-homepage') && getUserGeoCountry().includes('US') ;
  const [isMini, setIsMini] = useState(false);

  const tracking = useMemo(
    () => ({
      context_page_type: 'feed',
      context_page_id: 1,
      unit_type: 'modal',
      unit_name: 'shoppy_bot_promo',
      item_type: 'button',
    }),
    []
  );

  useEffect(() => {
    const hasSeenShoppy = localStorage.getItem('hasSeenShoppy');
    if (hasSeenShoppy) setIsMini(true);

    if (showShoppy) {
      trackImpression({
        ...tracking,
        item_name: hasSeenShoppy ? 'lets_shop_together' : 'lets_chat',
        position_in_unit: 1,
        target_content_type: 'feed',
        target_content_id: '6',
      })
    }
  }, [showShoppy]);

  const handleClose = () => {
    setIsMini(true);
    window.localStorage.setItem('hasSeenShoppy', true);

    trackContentAction(
      {
        ...tracking,
        item_name: 'close',
        position_in_unit: 0,
        action_type: 'select',
        action_value: 'close',
      }
    );
  };

  const handleClick = () => {
    trackInternalLink(
      {
        ...tracking,
        item_name: isMini ? 'lets_shop_together' : 'lets_chat',
        position_in_unit: 1,
        target_content_type: 'feed',
        target_content_id: '6',
      }
    )
  };

  /**
   * This component will be rendered offscreen to disqualify it as the largest contentful paint
   * (LCP) element. Once the image loads, the handler will apply a classname to trigger a CSS
   * animation for bringing the component back into view.
   * - https://web.dev/lcp/#how-are-element-layout-and-size-changes-handled
   * - https://screenspan.net/blog/cookie-banner-web-vitals/
   */
  const handleLoadHiddenLCP = (e) => {
    const container = e.target.parentElement;
    container.classList.add(styles.show);
  };

  return (
    <>
      { showShoppy && (
        <>
          { isMini ? (
            <div className={ styles.miniContainer }>
              <img
                alt="" src={backgroundClosedImg}
                aria-hidden="true"
                className={ styles.background }
                onLoad={handleLoadHiddenLCP}
              />
              <div
                className={ styles.miniMessage }
                onClick={ handleClick }
              >
                <a href="/giftguide" target="_blank" rel="noreferrer" >
                  Let’s shop together
                </a>
                <img className={ styles.miniShoppy } src={ shoppy } />
              </div>
              <img className={ styles.miniHat } src={ hat } />
            </div>
          ) : (
            <div className={ styles.container }>
                <img
                  alt="" src={backgroundImg}
                  aria-hidden="true"
                  className={ styles.background }
                  onLoad={handleLoadHiddenLCP}
                />
                <div
                  className={ styles.character }>
                  <a
                    href="/giftguide"
                    target="_blank"
                    rel="noreferrer"
                    onClick={ handleClick }
                  >
                    <img className={ styles.hat } src={ hat } />
                    <img className={ styles.shoppy } src={ shoppy } />
                    <div className={ styles.messageContainer }>
                      <div className={ styles.message }>
                        I’m your trusty guide Shoppy and I’m here to find the best
                        gifts for everyone on your list.
                      </div>
                      <div className={ styles.message }> How can I help you? </div>
                    </div>
                  </a>
                <div className={ styles.close } onClick={ handleClose }>
                  <XIcon height={ 13 } />
                  Close
                </div>
                </div>
              <div
                className={ styles.chatButton }
                onClick={ handleClick }
              >
                <a href="/giftguide" target="_blank" rel="noreferrer">
                  Let’s Chat
                  <img src={ arrowIcon } />
                </a>
              </div>
            </div>
          ) }
        </>
      ) }
    </>
  );
}

export default ShoppyClippy;
