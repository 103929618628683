import { Feed } from '../Feed';
import { useContext, useState, useEffect } from 'react';
import { AdsContext } from '@buzzfeed/adlib/dist/module/bindings/react/contexts';
import adsConfig from '../../constants/ads-config';
import { sizes } from '@buzzfeed/adlib/dist/module/bindings/react';
import sizeHelper from '@buzzfeed/adlib/services/size/standalone';
import { useMediaQuery } from '../../hooks';
import { SubunitTrackingContext } from '../../contexts';
import { StoryFeedManager } from '../Ads/managers/StoryFeedManager';

export const TrendingFeed = ({ data: { items, typeInfo } }) => {
  const { isMobile } = useMediaQuery();
  const adsContext = useContext(AdsContext);
  const [adManager, setAdManager] = useState(null);

  useEffect(() => {
    if (adsContext.status !== 'loaded') {
      return () => {};
    }
    const storyPos = isMobile ? 'story2' : 'story1';
    let unitConfig = adsConfig[storyPos];
    // remove 970xN sizes because left side only
    unitConfig.size = sizeHelper.exclude(
      unitConfig.size,
      sizes.PROGRAMMATIC_BILLBOARD,
      sizes.PROGRAMMATIC_SUPER_LEADERBOARD
    );
    const manager = new StoryFeedManager({
      config: {
        units: [adsConfig[storyPos]],
        placements: [3],
      },
    });

    manager.init().then(
      () => {
        setAdManager(manager);
      },
      // if there are no ads to show (on partner posts on desktop),
      // `init` will throw a `Disabled` error
      () => {}
    );
    return () => {
      setAdManager(null);
      manager.destroy();
    };
  }, [adsContext, isMobile]);

  const itemList = items.slice(0, 5);
  /* eslint-disable camelcase */
  const subunitTracking = {
    position_in_unit: 3,
    subunit_name: 'trending',
    subunit_type: 'package',
  };
  /* eslint-enable */

  return (
    <>
      {!!items?.length && (
        <SubunitTrackingContext.Provider value={subunitTracking}>
          <Feed
            items={itemList}
            heading="Trending"
            columns={1}
            typeInfo={typeInfo}
            hasRightText
            hasArrows
            hasRank
            hasSeeMoreLink={true}
            hasAds={true}
            adManager={adManager}
            pageSize={5}
            hideTrendingLabels={true}
          />
        </SubunitTrackingContext.Provider>
      )}
    </>
  );
};

export default TrendingFeed;
