/* eslint-disable camelcase */
import React from 'react';
import { SubunitTrackingProvider } from '../../contexts';
import LinkWithTracking from '../LinkWithTracking';
import BrandedBox from '../BrandedBox';
import Carousel from '../Carousel';
import Feed from '../Feed';
import {
  useViewImpression,
} from '../../hooks';
import styles from './dontMiss.module.scss';

export const DontMissDesktopCard = ({
  item,
  positionInSubunit,
}) => {
  const trackingData = {
    item_type: 'text',
    item_name: item.id,
    position_in_subunit: positionInSubunit,
    target_content_type: 'buzz',
    target_content_id: item.id,
  };
  // impression tracking
  const { setObservable } = useViewImpression({ trackingData });

  return (
    <li
      ref={obvEl => setObservable(obvEl)}
      className={styles.tileContent}
      key={item?.name}
    >
      <LinkWithTracking
        className="text-gray"
        href={item.url}
        commonTrackingData={trackingData}
      >
        <div className="xs-flex">
          <div className={styles.imageWrapper}>
            <img src={item.thumbnail} alt={item.thumbnailAlt} />
          </div>
          <div className={styles.text}> {item?.name}</div>
        </div>
      </LinkWithTracking>
    </li>
  );
};

export const DontMiss = ({ curatedData, name, topPosts }) => {
  const items =
    curatedData.items.length === 3 ? curatedData.items : topPosts.items;
  const title = curatedData.items.length === 3 ? name : 'Top Posts';
  const typeInfo = { type: 'buzzfeed', title };
  const dontMissHeading = <h2 className={styles.heading}>{title}</h2>;

  const tracking = {
    subunit_name: 'dont_miss',
    subunit_type: 'package',
    position_in_unit: 2,
  };

  return (
    <>
      <div className="md-hide">
        <SubunitTrackingProvider value={tracking}>
          {dontMissHeading}
          <Carousel color="yellow" items={items} />
        </SubunitTrackingProvider>
      </div>
      <div className="xs-hide md-block lg-hide">
        {/* make a package at this size */}
        <SubunitTrackingProvider value={tracking}>
          <BrandedBox
            heading={dontMissHeading}
            color="yellow"
            className="xs-pt4 xs-mb6 md-mb0"
          >
            <Feed
              items={items.slice(0, 3)}
              columns={3}
              typeInfo={typeInfo}
              hasRightText={false}
            />
          </BrandedBox>
        </SubunitTrackingProvider>
      </div>
      <div className="xs-hide lg-block">
        <SubunitTrackingProvider value={tracking}>
          <BrandedBox
            heading={dontMissHeading}
            color="yellow"
            className="md-mb6 md-mt4 md-px2 md-pb1"
          >
            <ul className={styles.desktopContainer}>
              {items.map((item, index) => (
                <DontMissDesktopCard
                  key={item.id}
                  item={item}
                  positionInSubunit={index}
                />
              ))}
            </ul>
          </BrandedBox>
        </SubunitTrackingProvider>
      </div>
    </>
  );
};

export default DontMiss;
