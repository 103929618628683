import React, { useContext, useEffect, useState, useMemo } from 'react';
import { XIcon } from '@buzzfeed/react-components';
import { getUserGeoCountry } from '@buzzfeed/bf-utils/lib/localization';
import { useTrackingContext } from '../../hooks/tracking/useTrackingContext';
import { ABeagleContext } from '../../contexts';

import styles from './styles.module.scss';
import grammysGif from './grammys.gif';

export function GrammysPromo () {
    const { trackInternalLink } = useTrackingContext();
  const { getFeatureFlagValue } = useContext(ABeagleContext);
  const showPromo = getFeatureFlagValue('homepage_grammys_promo') && getUserGeoCountry().includes('US') ;
  
  const [isClosed, setIsClosed] = useState(false);
  const [isCloseClicked, setIsCloseClicked] = useState(false);

  const tracking = useMemo(
    () => ({
      context_page_type: 'feed',
      context_page_id: 1,
      unit_type: 'modal',
      unit_name: 'grammys_promo',
      item_type: 'button',
    }),[]);

  const handleClose = () => {
    setIsCloseClicked(true);
    setTimeout(() => { setIsClosed(true) }, 500);
  };

    const handleClick = () => {
    trackInternalLink(
      {
        ...tracking,
        item_name: 'grammys_coverage',
        position_in_unit: 1,
        target_content_type: 'feed',
        target_content_id: '6',
      }
    )
  };


  return (
    <>
      { showPromo && !isClosed && (
        <>
          <div className={ `${styles.container} ${isCloseClicked ? styles.closed : ''}`}>
            <a href="/topic/grammys" target="_blank" rel="noreferrer" onClick={handleClick}>
              <img src={grammysGif} />
            </a>
            <div className={ styles.close } onClick={handleClose}>
                <XIcon height={ 13 } />
            </div>
          </div>

        </>
      ) }
    </>
  );
}

export default GrammysPromo;
