/*eslint-disable camelcase*/
import { useContext, useRef } from 'react';
import { getConfig } from '../utils/config';
import HomepageSiteHeader from '../components/SiteHeader';

// apis
import {
  getHomepageTop,
  getShoppingPackage,
  getTrendingQuizzes,
  getTopPosts,
} from '../upstreams/weaver';
import { getTastyTrending } from '../upstreams/tasty';
import { getSiteHeader } from '../upstreams/bf-header-ui';
import setResponseHeaders from '@buzzfeed/headers';
import { normalizeFeedData } from '../utils/data';
import { useMediaQuery } from '../hooks/useMediaQuery';
import {
  // NEWS,
  SHOPPING,
  TASTY,
  QUIZ,
  MORE,
  SPLASH,
  TRENDING,
  MAX_AGE_24HR,
  SPONSORED,
} from '../constants/feeds';

// feed components
import BreakingBar from '../components/BreakingBar';
import DontMiss from '../components/DontMiss';
import EverythingElse from '../components/EverythingElse';
import MoreStories from '../components/MoreStories';
import Quizzes from '../components/Quizzes';
import ShoppingPackage from '../components/ShoppingPackage';
import SplashPackage from '../components/SplashPackage';
import TastyTrending from '../components/TastyTrending';
import TrendingFeed from '../components/TrendingFeed';
import SponsoredPackage from '../components/SponsoredPackage';

// other components
import CustomHead from './_head';
import HomepageWrapper from '../components/HomepageWrapper';
import BackToTop from '@buzzfeed/react-components/lib/components/BackToTop';
import ShoppyClippy from '../components/ShoppyClippy';
import GrammysPromo from '../components/GrammysPromo';

// ad components
import { AdInFeed } from '../components/Ads/units/AdInFeed';
import { AdSection, AdSectionWide } from '../components/Ads/units/AdSection';
import StickyAd from '../components/Ads/units/AdStickySidebar';
import { getFeedTakeover } from '../upstreams/curator-api';
import FeedFlip from '../components/FeedFlip';
import { sponsorData } from '../utils/fyf-sponsor-data';
import { TakeoverContext } from '../contexts';
import AdmiralScript from '../components/AdmiralScript';

export function Page({
  breakingBar,
  dontMiss,
  header,
  moreStories,
  pinnedPosts,
  sponsoredPackage,
  quizzes,
  recipes,
  shopping,
  splash,
  trending,
  topPosts,
  pageConfig,
}) {
  const takeover = useContext(TakeoverContext);
  const moreStories1 = moreStories.slice(0, 6);
  const moreStories2 = moreStories.slice(6, 12);
  const moreStories3 = moreStories.slice(12, 18);
  const moreStories4 = moreStories.slice(18, 24);
  const everythingElse = moreStories.slice(24);

  // refs for sticky
  const splashSidebar = useRef(null);
  const quizSidebar = useRef(null);
  const shoppingSidebar = useRef(null);
  const tastySidebar = useRef(null);
  const screenSize = useMediaQuery();

  return (
    <>
      <HomepageWrapper pageName={'home'}>
        <AdmiralScript />
        <HomepageSiteHeader {...header} />
        <CustomHead pageConfig={pageConfig} inlineCss={takeover?.inlineCss} />
        {breakingBar && (
          <BreakingBar
            text={breakingBar.name}
            link={breakingBar?.links[0]?.url || ''}
            label={breakingBar?.label || 'BREAKING'}
          />
        )}
        {takeover?.feedFlip?.enabled && (
          <FeedFlip sponsor={sponsorData} isChecked={false} />
        )}
        <main id="buzz-content" className="homepage-content md-pt2 xs-px1" style={takeover?.style}>
          <div className="homepage-container">
            <div className="md-flex">
              <div className="homepage-leftColumn">
                <SplashPackage
                  splash={splash}
                  data={normalizeFeedData(pinnedPosts.items, SPLASH, 'hppin')}
                />
                {/* Trending - DESKTOP */}
                {screenSize.isLargeScreen && (
                  <>
                    {trending && (
                      <>
                        <TrendingFeed
                          data={normalizeFeedData(trending, TRENDING, 'tuh')}
                        />
                        <AdInFeed type="story2" className="xs-my5" />
                      </>
                    )}
                  </>
                )}

                {/* Don't Miss - MOBILE + TABLET (xs->md) */}
                {!screenSize.isLargeScreen && (
                  <>
                    {(dontMiss?.items?.length > 1 || topPosts?.length > 0) && (
                      <DontMiss
                        curatedData={normalizeFeedData(
                          dontMiss.items,
                          MORE,
                          'hpdm'
                        )}
                        topPosts={normalizeFeedData(topPosts, MORE, 'hpdm')}
                        name={dontMiss.name}
                      />
                    )}
                    <AdSection posnum={1} isMobile={screenSize.isMobile} />
                  </>
                )}

                {/* Trending Feed - MOBILE */}
                {screenSize.isMobile && (
                  <>
                    {trending && (
                      <TrendingFeed
                        data={normalizeFeedData(trending, TRENDING, 'tuh')}
                      />
                    )}
                    {/* TrendingFeed has mweb posnum (story) 2 */}
                    <AdSection posnum={3} isMobile={screenSize.isMobile} />
                  </>
                )}

                {/* Sponsored Package */}
                {sponsoredPackage && (
                  <>
                    <SponsoredPackage
                      sponsor={sponsoredPackage.sponsor}
                      name={sponsoredPackage.name}
                      id={sponsoredPackage.id}
                      data={normalizeFeedData(
                        sponsoredPackage.items,
                        SPONSORED,
                        'shp'
                      )}
                      cta={sponsoredPackage.cta}
                    />
                  </>
                )}

                {!!moreStories1.length && (
                  <MoreStories
                    data={normalizeFeedData(moreStories1, MORE, 'web-hf')}
                    positionInUnit={4}
                    subunitName="recent_stories"
                    heading="Latest Stories"
                    className="md-mb3 lg-mb5"
                  />
                )}
              </div>

              {/* Don't Miss - DESKTOP */}
              <div ref={splashSidebar} className="homepage-rightColumn">
                {screenSize.isLargeScreen && (dontMiss?.items?.length > 1 || topPosts?.length > 0) && (
                  <>
                    <DontMiss
                      curatedData={normalizeFeedData(
                        dontMiss.items,
                        MORE,
                        'hpdm'
                      )}
                      topPosts={normalizeFeedData(topPosts, MORE, 'hpdm')}
                      name={dontMiss.name}
                    />
                    <StickyAd adPos="bigstory" sidebarEl={splashSidebar} />
                  </>
                )}
              </div>
            </div>

            {!!moreStories1 && (
              <>
                <AdSectionWide posnum={3} isMobile={screenSize.isMobile} />
                <AdSection posnum={4} isMobile={screenSize.isMobile} />
              </>
            )}

            <div className="xs-flex">
              <div className="homepage-leftColumn">
                {shopping && shopping.items && (
                  <ShoppingPackage
                    name={shopping.name}
                    data={normalizeFeedData(shopping.items, SHOPPING, 'hshp')}
                  />
                )}
                <AdSection posnum={5} isMobile={screenSize.isMobile} />

                {!!moreStories2 && (
                  <MoreStories
                    data={normalizeFeedData(moreStories2, MORE, 'web-hf')}
                    positionInUnit={6}
                    subunitName="more_stories_2"
                    className="md-mb3 lg-mb5"
                  />
                )}
              </div>
              {screenSize.isLargeScreen && (
                <div ref={quizSidebar} className="homepage-rightColumn xs-mt6">
                  <StickyAd adPos="promo1" sidebarEl={quizSidebar} />
                </div>
              )}
            </div>
            {!!moreStories2.length && (
              <>
                <AdSectionWide posnum={4} isMobile={screenSize.isMobile} />
                <AdSection posnum={6} isMobile={screenSize.isMobile} />
              </>
            )}

            <div className="xs-flex">
              <div className="homepage-leftColumn">
                {quizzes?.length > 0 && <Quizzes data={normalizeFeedData(quizzes, QUIZ, 'tuh')} />}
                <AdSection posnum={7} isMobile={screenSize.isMobile} />

                {!!moreStories3.length && (
                  <MoreStories
                    data={normalizeFeedData(moreStories3, MORE, 'web-hf')}
                    positionInUnit={8}
                    subunitName="more_stories_3"
                    className="md-mb3 lg-mb5"
                  />
                )}
              </div>
              {screenSize.isLargeScreen && (
                <div
                  ref={shoppingSidebar}
                  className="homepage-rightColumn xs-mt6"
                >
                  <StickyAd adPos="promo2" sidebarEl={shoppingSidebar} />
                </div>
              )}
            </div>
            {!!moreStories3 && (
              <>
                <AdSectionWide posnum={5} isMobile={screenSize.isMobile} />
                <AdSection posnum={8} isMobile={screenSize.isMobile} />
              </>
            )}

            {/* Tasty */}
            <div className="xs-flex">
              <div className="homepage-leftColumn">
                {!!recipes?.length && (
                  <TastyTrending data={normalizeFeedData(recipes, TASTY)} />
                )}
                <AdSection posnum={8} isMobile={screenSize.isMobile} />

                {!!moreStories4.length && (
                  <MoreStories
                    data={normalizeFeedData(moreStories4, MORE, 'web-hf')}
                    positionInUnit={11}
                    subunitName="more_stories_4"
                  />
                )}
              </div>
              {screenSize.isLargeScreen && (
                <div ref={tastySidebar} className="homepage-rightColumn xs-mt6">
                  <StickyAd adPos="promo3" sidebarEl={tastySidebar} />
                </div>
              )}
            </div>
            {!!moreStories4.length && (
              <>
                <AdSectionWide posnum={6} isMobile={screenSize.isMobile} />
                <AdSection posnum={9} isMobile={screenSize.isMobile} />
              </>
            )}

            {/* Everything Else */}
            <EverythingElse
              positionInUnit={13}
              data={normalizeFeedData(everythingElse, MORE, 'web-hf')}
            />
          </div>
          <BackToTop />
          <ShoppyClippy />
          <GrammysPromo />
        </main>
      </HomepageWrapper>
    </>
  );
}

export async function getServerSideProps({ req, res }) {
  const edition = 'en-us'; // for future use when determining editions after mvp rollout
  const staticProps = {
    namespacesRequired: ['common'],
  };

  const weaverParams = { edition };

  try {
    const [
      header, // Fetch Error --> 500
      topItems, // Fetch Error --> 500
      quizzes, // Fetch Error --> graceful (do not display)
      recipes, // Fetch Error --> graceful (do not display)
      shopping, // Fetch Error --> graceful (do not display)
      topPosts, // Fetch Error --> graceful (do not display)
      takeover, // Fetch Error --> graceful (do not display)
      // eslint-disable-next-line no-undef
    ] = await Promise.all([
      getSiteHeader(weaverParams),
      getHomepageTop(weaverParams),
      getTrendingQuizzes(weaverParams),
      getTastyTrending(),
      getShoppingPackage(weaverParams),
      getTopPosts(weaverParams),
      getFeedTakeover({ previewId: req.query.takeover_preview_id }),
    ]);

    // if theres, no splash something is wrong...
    if (topItems === null) {
      throw Error('Top Items (and splash) do not exist');
    }

    setResponseHeaders({
      req,
      res,
      maxAge: MAX_AGE_24HR,
      edition: 'en-us',
      contentIDs: [
        { type: 'feed', id: 'buzzfeed-home' },
        { type: 'feed', id: 'trending' },
        { type: 'feed', id: 'trending-quizzes' },
        { type: 'feed', id: 'shopping-package' },
        { type: 'feed', id: 'home-flipped' },
      ]
    });

    return {
      props: {
        ...staticProps,
        header,
        ...topItems,
        quizzes,
        recipes,
        shopping,
        topPosts,
        pageConfig: getConfig({ page: 'default', edition, path: req.path }),
        takeover,
        tracking: {
          unit_type: 'feed',
          unit_name: 'main',
          context_page_type: 'feed',
          context_page_id: 1,
          destination: 'buzzfeed',
          page_edition: 'en-us',
        },
      },
    };
  } catch (error) {
    // Handle error
    console.error('getServerSideProps failed to complete execution on home page');
    console.error(error);
    throw error;
  }
}

export default Page;
