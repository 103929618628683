/* eslint-disable camelcase */
import React from 'react';
import LinkWithTracking from '../LinkWithTracking';
import BrandedBox from '../BrandedBox';
import { useViewImpression } from '../../hooks';
import SeeMoreLink from '../SeeMoreLink/SeeMoreLink.jsx';
import styles from './carousel.module.scss';

export const Card = ({
  color = 'yellow',
  item,
  positionInSubunit,
}) => {
  const trackingData = {
    item_type: 'text',
    item_name: item.id,
    position_in_subunit: positionInSubunit,
    target_content_type: 'buzz',
    target_content_id: item.id,
  };
  // impression tracking
  const { setObservable } = useViewImpression({ trackingData });

  return (
    <li
      ref={obvEl => setObservable(obvEl)}
      className={styles.itemWrapper}
      key={item?.name}
    >
      <LinkWithTracking
        className="text-gray"
        href={item.url}
        commonTrackingData={trackingData}
      >
        <BrandedBox color={color} className="xs-p0 xs-mt0" isFullHeight>
          <div className={styles.carouselCard}>
            <div className="xs-flex xs-flex-column">
              <div className={styles.imageWrapper}>
                <img src={item.thumbnail} alt={item.thumbnailAlt} loading="lazy"/>
              </div>
              <div className={styles.text}> {item?.name}</div>
            </div>
          </div>
        </BrandedBox>
      </LinkWithTracking>
    </li>
  );
};

export const Carousel = ({
  items,
  color,
  seeMoreLink,
}) => {
  let seeMoreTrackingData = {};

  if (seeMoreLink) {
    seeMoreTrackingData = {
      item_type: 'text',
      item_name: `see_more_${seeMoreLink.seeMoreTrackingName}`,
      position_in_subunit: items.length + 1,
      target_content_type: 'buzz',
      target_content_id: seeMoreLink.seeMoreTrackingName,
    };
  }

  return (
    <>
      <ul
        className={`${styles.carouselContainer} ${
          !seeMoreLink ? 'xs-mb5' : ''
        }`}
      >
        {items.map((item, index) => (
          <Card
            color={color}
            key={item.id}
            item={item}
            positionInSubunit={index}
          />
        ))}
      </ul>
      {seeMoreLink?.url && (
        <SeeMoreLink
          href={seeMoreLink.url}
          commonTrackingData={seeMoreTrackingData}
          className="xs-flex xs-text-4 md-text-2 bold xs-flex-align-center link-blue xs-mt1 xs-mb5"
        >
          See More {seeMoreLink.title}
        </SeeMoreLink>
      )}
    </>
  );
};

export default Carousel;
