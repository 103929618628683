import React from 'react';
import styles from './brandedBox.module.scss';

export const BrandedBox = ({
  heading,
  color,
  children,
  className,
  isFullHeight = false,
}) => {
  return (
    <div className={`${styles[color]} ${isFullHeight ? 'xs-full-height' : ''}`}>
      <div
        className={`
        ${styles.wrapper} 
        ${className} 
        ${isFullHeight ? styles.fullHeightWrapper : ''}`}
      >
        {heading && <div className={styles.heading}>{heading}</div>}
        {children}
      </div>
    </div>
  );
};

export default BrandedBox;
